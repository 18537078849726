import AWS from './awsconfig';

// event : file upload object
// folder: folder name
export function handleFileUploadS3(event, folder) {
    return new Promise((resolve, reject) => {
        // Perform the file upload operation
        // Replace the following code with your actual implementation
        // Assuming you are using an asynchronous API or library for the upload
        if (event && event.length > 0) {
            const file = event[0];
              const fileName = file.name;
              const folderPath = 'playace/'+folder+"/"; // Desired folder path //profiles
              const objectKey = folderPath + fileName;
      
              const s3 = new AWS.S3();
              const params = {
                  Bucket: process.env.REACT_APP_S3_BUCKET,
                  Key: objectKey,
                  Body: file,
                  ACL: 'public-read' // Set the desired ACL (Access Control List) for the uploaded file
              };
      
              s3.upload(params, (err, data) => {
              if (err) {
                  console.error('Error', err);
                  resolve(null);
              } else {
                  resolve(data.Location);
              }
              });
        }
      });
    
}



