import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import LoadingOverlay from "react-loading-overlay";
import Select from "react-select";
import { toast } from "react-toastify";

import ThankyouImage from "../../../assets/Frame 656.png"; // Adjust the path as needed
import {
  createEventRequest,
  fetchNewCities,
  getCities,
} from "../../../api/requests"; // Adjust the path as needed
import styles from "./PlanningOverview.module.scss"; // Adjust the path as needed

import "react-datepicker/dist/react-datepicker.css";
import { RenderUserObj } from "../../helpers";
import { showErrorToast, showSuccessToast } from "../../../utils/utils";

const HousePartyModal = ({ show, handleClose }) => {
  const state = useSelector((state) => state.hostParty);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState(null);
  const [pincode, setPincode] = useState("");
  const [date, setDate] = useState(null);
  const [cities, setCities] = useState([]);
  const [showRequestSentModal, setShowRequestSentModal] = useState(false);
  const history = useHistory();
  let userObj = RenderUserObj(useSelector((state) => state.auth.data));

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#27282C",
      color: "#fff",
      fontFamily: "Kumbh Sans, sans-serif",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#fff",
      fontFamily: "Kumbh Sans, sans-serif",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#27282C",
      fontFamily: "Kumbh Sans, sans-serif",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#3a3b3f" : "#27282C",
      color: "#fff",
      fontFamily: "Kumbh Sans, sans-serif",
    }),
  };

  useEffect(() => {
    console.log("state.auth", state.auth);
    getCities().then((res) => {
      console.log(res?.data);
      const dropdownOptions = res?.data?.map((option) => ({
        label: option.city,
        value: option.id,
      }));
      setCities(dropdownOptions);
    });
    // fetchNewCities(userObj?.token).then((response) => {
    //   const dropdownOptions = response?.data?.map((option) => ({
    //     label: option,
    //     value: id,
    //   }));
    //   setCities(dropdownOptions);
    //   setLoading(false);
    // });
  }, [userObj?.token]);

  const onCLose = () => {
    console.log("onClose");
    setAddress("");
    setCity(null);
    setPincode("");
    setDate(null);
    handleClose();
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let partyRequest = {
      address: address,
      eventDate: moment(date).format("yyyy-MM-DD"),
      cityId: city,
      pincode: parseInt(pincode),
    };
    createEventRequest(userObj?.token, partyRequest).then((response) => {
      console.log(response);
      setLoading(false);

      if (response.status === "success") {
        // toast.success("Event Sent For Approval!");
        showSuccessToast("Event Sent For Approval!");

        setShowRequestSentModal(true);
        onCLose();
      } else {
        // toast.error("Something went wrong! Try Again Later.");
        showErrorToast(response.message ?? "Something went wrong! Try Again Later.");
        onCLose();
      }
    });
  };

  const handlePincode = (e) => setPincode(e.target.value);
  const handleAddress = (e) => setAddress(e.target.value);
  const handleCity = (selectedOption) => {
    setCity(selectedOption);
    console.log(selectedOption);
  };

  const handleCloseRequestSentModal = () => {
    setShowRequestSentModal(false);
    // history.push("/");
  };

  return (
    <LoadingOverlay active={loading} spinner text="">
      <Modal
        show={show}
        onHide={onCLose}
        centered
        className="hostPartyModal"
      >
        <Modal.Body>
          <div className="upperHead">
            <h2>Let's host a house party!</h2>


            <div className="crossContainer" onClick={() => onCLose()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-x"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M18 6l-12 12" />
                <path d="M6 6l12 12" />
              </svg>
            </div>
          </div>

          <p className="disclamhm">
            Share some quick details to get one step closer to hosting a great
            house party.
          </p>

          <form
            onSubmit={onSubmit}
            className="hostPartyModalForm"
          >

            <>
              <div className="d-flex flex-column position-relative">
                <label>
                  <h5>Preferred Party Date</h5>
                </label>
                <DatePicker
                  className="input w-100"
                  selected={date}
                  minDate={new Date()}
                  onChange={(e) => setDate(e)}
                  placeholderText="dd/mm/yyyy"
                />
                {/* <p className="text-danger">{errors.birthDate && "Required"}</p> */}
              </div>
              <div className="">
                <label>
                  <h5>Locality</h5>
                </label>
                <input
                  name="currentAddress"
                  type="text"
                  onChange={handleAddress}
                  placeholder="Complete address of the party"
                  value={address}
                  className="form-control"
                />
              </div>
              <div className="row">
                <div className="col">
                  <label>
                    <h5>City</h5>
                  </label>
                  {/* <Select
                    value={city}
                    onChange={handleCity}
                    options={cities}
                    styles={customSelectStyles}
                  /> */}

                  <div className="selectCont">
                    <select
                      value={city}
                      onChange={(e) => handleCity(e.target.value)}
                    >
                      <option>Select City</option>
                      {cities.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-down"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M6 9l6 6l6 -6" />
                    </svg>
                  </div>
                </div>
                <div className="col">
                  <label>
                    <h5>Pincode</h5>
                  </label>
                  <input
                    name="pincode"
                    type="text"
                    onChange={handlePincode}
                    placeholder="City Pincode"
                    value={pincode}
                    className="form-control"
                  />
                </div>
              </div>
              <Button
                type="submit"
                className="btn btn-primary mt-3 py-2 wfull hostPartySubmitBtn"
                disabled={!pincode || !city || !address || !date}
              >
                Send Request
              </Button>

              <p className="smolformInfo">
                Our team shall connect with you shortly if we are serviceable in your area.
              </p>
            </>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showRequestSentModal}
        backdrop="static"
        onHide={handleCloseRequestSentModal}
        centered
      >
        <div
          className={"d-flex flex-column justify-content-center text-center"}
        >
          <div className="gotItCont">
            <img
              src={ThankyouImage}
              alt="Request-sent-image"
              className="middlePopupImg"
            />
            <h5>Party Request Sent !</h5>
            <p>
              Your hosting request is being reviewed. Our party experts will get
              in touch with you shortly
            </p>
            <button
              className="btn btn-primary fw-bold px-4 mt-2 mb-3 mx-auto gotitBoss"
              type="submit"
              onClick={handleCloseRequestSentModal}
            >
              Okay, Got It
            </button>
          </div>
        </div>
      </Modal>
    </LoadingOverlay>
  );
};

export default HousePartyModal;
