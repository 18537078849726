import React from "react";
import { useSelector } from "react-redux";
import { Mobile, Default } from "../../helpers/Breakpoints";
import {
  createEventReview,
  fetchEventParticipantsByEventPhone,
  fetchPartyById,
  fetchUser,
} from "../../../api/requests";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import StarRatings from "./EventDetails/starRatings";
import About from "./EventDetails/About";
import EventHostImages from "../../pages/Home/eventHostImages";

function EventReview() {
  const { eventId } = useParams();
  const isAuthenticated = useSelector((state) => state.auth);
  const isData = useSelector((state) => state.auth.data);
  let userObj = {};

  if (isAuthenticated.isAuthenticated) {
    userObj = JSON.parse(isData ? isData : []);
  }

  const [userData, setUserData] = useState([]);
  const [hostData, setHostData] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedRating, setSelectedRating] = useState(null);
  const [reviewComment, setReviewComment] = useState("");
  const [reviewMessage, setReviewMessage] = useState("");
  const [eventParticipant, setEventParticipant] = useState(null);

  useEffect(() => {
    if (isAuthenticated.isAuthenticated) {
      fetchEventParticipantsByEventPhone(
        userObj.token,
        eventId,
        userObj?.data?.phone
      ).then((item) => {
        setEventParticipant(item.data);
      });

      fetchPartyById(eventId).then((response) => {
        setEvents(response.data);
        if (response.data) {
          let userData = fetchUser("fetchUser", null, response.data.host).then(
            (response) => {
              setHostData(response.data);
            }
          );
        }
      });

      let userData = fetchUser("fetchUser", null, userObj.userId).then(
        (response) => {
          setUserData(response.data);
        }
      );
    }
  }, [eventId, isAuthenticated, userObj.phone, userObj.token, userObj.userId]);

  const handleRatingChange = (selected) => {
    setSelectedRating(selected);
  };

  const submitReview = () => {
    if (selectedRating == null || selectedRating == "") {
      setReviewMessage(" - Invalid ratings ");
    } else if (reviewComment == null || reviewComment == "") {
      setReviewMessage(" - Review cannot be empty");
    } else {
      let payload = {
        event: {
          eventId: events.eventId,
          title: events.title,
        },
        participant: {
          userId: userData.userId,
          fullName: userData.fullName,
          profileImage: userData.profileImage,
        },
        host: {
          userId: hostData.userId,
          fullName: hostData.fullName,
          profileImage: hostData.profileImage,
        },
        rating: selectedRating || 1,
        comment: reviewComment,
      };
      console.log("payload", payload);
      createEventReview(userObj.token, payload)
        .then((response) => {
          setReviewMessage(response.message);
          if (response.status === "success") {
            setTimeout(() => {
              setReviewMessage("");
              setReviewComment("");
              setSelectedRating(null);
            }, 2000);
          }
        })
        .catch((response) => {
          // setReviewMessage(response.message)
        });
    }
  };

  return (
    <>
      <div
        style={{ minHeight: "40vh", margin: "7rem auto", textAlign: "center" }}
      >
        {!isAuthenticated.isAuthenticated && (
          <>
            <Default>
              <Container>
                <Row>
                  <Col lg={12} md={12}>
                    <h5>Please Login to Submit your review. Thank You!</h5>
                  </Col>
                </Row>
              </Container>
            </Default>
            <Mobile>
              <Container>
                <Row>
                  <Col lg={12} md={12}>
                    <h5>Please Login to Submit your review. Thank You!</h5>
                  </Col>
                </Row>
              </Container>
            </Mobile>
          </>
        )}

        {userData && isAuthenticated.isAuthenticated && (
          <>
            <Default>
              <Container>
                <Row>
                  <Col lg={8} md={8}>
                    {events?.images?.length > 1 ? (
                      events?.images && (
                        <EventHostImages
                          images={events?.images}
                        ></EventHostImages>
                      )
                    ) : (
                      <div className="staticImageShow">
                        <img src={events?.images?.[0]} alt="" />
                      </div>
                    )}

                    <About
                      style={{ textAlign: "left" }}
                      title="About the Party"
                      content={events}
                      type="party"
                      pageType="event-reviews"
                    />
                  </Col>
                  <Col lg={4} md={4}>
                    {/* <h3 className='m-3'>
                                {events.title}
                            </h3> */}

                    {eventParticipant?.length == 0 ? (
                      <div>
                        <br></br>
                        <br></br>
                        <h5 className="text-primary">
                          {" "}
                          Oops! look like you have not participated to this
                          event.
                        </h5>
                      </div>
                    ) : (
                      <div className="card p-4" style={{ textAlign: "left" }}>
                        <h4 style={{ textAlign: "center" }}>Add Review</h4>
                        <br></br>
                        <label className="text-primary mb-0">Ratings</label>
                        <StarRatings
                          totalStars={5}
                          onChange={handleRatingChange}
                        />
                        <br></br>
                        <label className="text-primary mb-0">Review</label>
                        <textarea
                          name={"fullName"}
                          value={reviewComment}
                          onChange={(e) => {
                            setReviewComment(e.target.value);
                          }}
                          pattern="^[a-zA-Z\s]$"
                          required
                          placeholder="Write..."
                        ></textarea>
                        <p className="text-primary">{reviewMessage}</p>
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-primary text-uppercase fw-bold"
                            type="submit"
                            onClick={submitReview}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </Default>
            <Mobile>
              <Container>
                <Row>
                  <Col lg={8} md={8}>
                    {events?.images?.length > 1 ? (
                      events?.images && (
                        <EventHostImages
                          images={events?.images}
                        ></EventHostImages>
                      )
                    ) : (
                      <div className="staticImageShow">
                        <img src={events?.images?.[0]} alt="" />
                      </div>
                    )}

                    <About
                      style={{ textAlign: "left" }}
                      title="About the Party"
                      content={events}
                      type="party"
                      pageType="event-reviews"
                    />
                  </Col>
                  <Col lg={4} md={4}>
                    {/* <h3 className='m-3'>
                                {events.title}
                            </h3> */}

                    {eventParticipant?.length == 0 ? (
                      <div>
                        <br></br>
                        <br></br>
                        <h5 className="text-primary">
                          {" "}
                          Oops! look like you have not participated to this
                          event.
                        </h5>
                      </div>
                    ) : (
                      <div className="card p-4" style={{ textAlign: "left" }}>
                        <h4 style={{ textAlign: "center" }}>Add Review</h4>
                        <br></br>
                        <label className="text-primary mb-0">Ratings</label>
                        <StarRatings
                          totalStars={5}
                          onChange={handleRatingChange}
                        />
                        <br></br>
                        <label className="text-primary mb-0">Review</label>
                        <textarea
                          name={"fullName"}
                          value={reviewComment}
                          onChange={(e) => {
                            setReviewComment(e.target.value);
                          }}
                          pattern="^[a-zA-Z\s]$"
                          required
                          placeholder="Write..."
                        ></textarea>
                        <p className="text-primary">{reviewMessage}</p>
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-primary text-uppercase fw-bold"
                            type="submit"
                            onClick={submitReview}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </Mobile>
          </>
        )}
      </div>
    </>
  );
}

export default EventReview;
