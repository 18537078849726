import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import { Mobile, Default } from "../../helpers/Breakpoints";

import Attend from "../../assets/attend.svg";
import Host from "../../assets/host.svg";
import Earn from "../../assets/earn.svg";

SwiperCore.use([Navigation, Pagination]);

function Ahe() {
  return (
    <div className="ahe-style bg-gray">
      <Container>
        <Default>
          <Row className="text-center">
            <Col
              md={4}
              xs={12}
              //className={`px-5 d-flex justify-content-center align-items-center`}
              className="text-center mx-auto"
            >
              <img src={Host} alt="illustration" className="img-fluid ahe-img mx-auto" />
              <div className="ml-3 text-muted">
                <h5 className={`fw-bold text-uppercase mt-2 text-primary`}>
                  Become a host
                </h5>
                <p>
                Choose a theme, set a price, accept bookings and meet like-minded strangers.
                </p>
              </div>
            </Col>
            <Col
              md={4}
              xs={12}
              //className={`px-5 d-flex justify-content-center align-items-center border-left border-right`}
              className="text-center mx-auto"
            >
              <img src={Attend} alt="illustration" className="img-fluid ahe-img mx-auto" />
              <div className="ml-3 text-muted">
                <h5 className={`fw-bold text-uppercase mt-2 text-secondary`}>
                  Attend a Party
                </h5>
                <p>
                Send a request to attend and be a part of unique experiences every time.
                </p>
              </div>
            </Col>
            <Col
              md={4}
              xs={12}
              //className={`px-5 d-flex justify-content-center align-items-center`}
              className="text-center mx-auto"
            >
              <img src={Earn} alt="illustration" className="img-fluid ahe-img mx-auto" />
              <div className="ml-3 text-muted">
                <h5 className={`fw-bold text-uppercase mt-2 text-primary`}>
                  Start Earning
                </h5>
                <p>
                Hosting allows you to earn extra money while having fun.
                </p>
              </div>
            </Col>
          </Row>
        </Default>
        <Mobile>
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true, type: "progressbar" }}
          >
            <SwiperSlide>
              <div
                // className={`px-5 d-flex justify-content-center align-items-center`}
                className="text-center mx-auto"
              >
                <img
                  src={Host}
                  alt="illustration"
                  className="img-fluid ahe-img mx-auto"
                />
                <div className="text-center ml-2 text-muted">
                  <h5 className={`fw-bold text-uppercase mt-2 text-primary`}>
                    Become a host
                  </h5>
                  <p>
                  Choose a theme, set a price, <br/>accept bookings and meet like-minded strangers.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                //className={`px-5 d-flex justify-content-center align-items-center`}
                className="text-center mx-auto"
              >
                <img
                  src={Attend}
                  alt="illustration"
                  className="img-fluid ahe-img mx-auto"
                />
                <div className="text-center ml-2 text-muted">
                  <h5 className={`fw-bold text-uppercase mt-2 text-secondary`}>
                    Attend a party
                  </h5>
                  <p>
                  Send a request to attend and <br/>be a part of unique experiences every time.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                //className={`px-5 d-flex justify-content-center align-items-center`}
                className="text-center mx-auto"
              >
                <img
                  src={Earn}
                  alt="illustration"
                  className="img-fluid ahe-img mx-auto"
                />
                <div className="text-center ml-2 text-muted">
                  <h5 className={`fw-bold text-uppercase mt-2 text-primary`}>
                    Start Earning
                  </h5>
                  <p>
                  Hosting allows you to <br/>earn extra money while having fun.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Mobile>
      </Container>
    </div>
  );
}

export default Ahe;