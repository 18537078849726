import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
} from "react";
import { Col, Row, Modal } from "react-bootstrap";
import ReactPixel from "react-facebook-pixel";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  IconRosetteDiscount,
  IconRosetteDiscountCheck,
} from "@tabler/icons-react";

import Auth from "../../../modules/auth/Auth";
import { Default, Mobile } from "../../../helpers/Breakpoints";
import {
  RenderDate,
  RenderUserObj,
  Loader,
  isGuestListEmpty,
} from "../../../helpers";
import avatar from "../../../../assets/square_logo.png";
import checked from "../../../../assets/ic_checked.png";
import coupon from "../../../../assets/ic_coupon.png";
import { useMutation } from "react-query";
import {
  sendRazorpayRequest,
  showRazorpay,
} from "../../../modules/payments/Razorpay";
import {
  requestToAttend,
  applyCouponCode,
  fetchPartyById,
  getParticipantCountAndMembersLimit,
  fetchEventsByHostId,
  getQuotation,
} from "../../../../api/requests";
import {
  setBookClickedFalse,
  setBookClickedTrue,
} from "../../../../actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import DefaultDP from "../../../../assets/DP.png";

import { calculateDiscount } from "../../../helpers";
import {
  updateGuestCount,
  updatePromoCode,
  updateQuotationDAta,
} from "../../../../actions/guestBookActions";
import { getLoader, showErrorToast } from "../../../../utils/utils";

const BookTicket = React.forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    handlePaymentFromParent() {
      handlePayment();
    },
  }));
  // export default function BookTicket(props) {
  // const [message, setMessage] = useState(false);
  const [message, setMessage] = useState("");
  const bookClicked = useSelector((state) => state.auth.bookClicked);

  const guests = useSelector((state) => state.guestBook.guestCount);
  const quotationData = useSelector((state) => state.guestBook.quotationData);

  const promoCode = useSelector((state) => state.guestBook.promoCode);

  const handleApplyPromo = () => {
    callQuotationApi(promoCode);
  };

  const handleRemovePromo = () => {
    dispatch(updatePromoCode(""));
    callQuotationApi("");
  };

  const [loading, setLoading] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [expired, setExpired] = useState("");
  const [couponData, setCouponData] = useState(null);

  let [showLoginModal, setShowLoginModal] = useState(null);
  const [actualAmount, setActualAmount] = useState(0);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  let userObj = useSelector((state) => state.auth.data);

  userObj = isAuthenticated ? JSON.parse(userObj) : [];

  const authState = useSelector((state) => state.auth);

  const token = (userObj && userObj.token) || null;
  // let [bookClicked, setbookClicked] = useState(null);
  const [mutate, info] = useMutation(requestToAttend);

  const handleClose = () => {
    setShowLoginModal(false);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const [apiError, setApiError] = useState("");

  useEffect(() => {
    callQuotationApi(promoCode);
  }, [guests, props.party]);

  const callQuotationApi = async (code) => {
    const response = await getQuotation({
      eventId: props?.party?.data?.eventId,
      guests: guests,
      couponCode: code,
    });
    if (response.status === "success") {
      dispatch(updateQuotationDAta(response.data));

      if (response.data.isCouponValid) {
        setApiError("");
      } else {
        setApiError(response?.message);
      }
    }
  };
  //   function getNameById() {
  // 	const selectedEvent = userData.userId === props?.party?.data?.host;
  // 	return selectedEvent ? userData.fullName : "";
  //   }
  const formatTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");
    date.setHours(hours);
    date.setMinutes(minutes);

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString("en-US", options);
  };

  useEffect(() => {
    setIsPaying(false);

    const time = props?.party?.data?.startTime;
    const dt = moment(time, ["h:mm A"]).format("HH:mm");
    const newPartyDate = dt.split(":");

    const partyHour = parseInt(newPartyDate[0]);
    const partyMinute = parseInt(newPartyDate[1]);

    const d = new Date();
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const currentHour = d.getHours();
    const currentMinute = d.getMinutes();

    const currentTime = new Date();
    currentTime.setHours(currentHour, currentMinute, 0);

    const currentDateArray = [parseInt(year), parseInt(month), parseInt(day)];
    currentDateArray.push(currentHour, currentMinute, 0);

    const partyDate = new Date(props?.party?.data?.eventDate);

    const currentDate = new Date(...currentDateArray);

    const invitationDeadline = new Date(props?.party?.data?.invitationEndDate);

    // const thirtyMinutesGap = 30 * 60 * 1000;
    const invitationValid = moment(invitationDeadline).isSameOrAfter(
      currentDate,
      "day"
    );
    const partyValid = partyDate > currentDate;

    if (invitationValid) {
      setExpired(partyValid ? "" : "Bookings Closed");
    } else {
      setExpired("Bookings Closed");
    }

    ReactPixel.init("1890035134379309");
    ReactPixel.track("PageView");
  }, []);

  const partyDateTime = props?.party?.data?.startTime;
  const partyDate = new Date(props?.party?.data?.eventDate);

  const currentDateTime = new Date();
  const currentTime = currentDateTime.toTimeString().slice(0, 5);

  useEffect(() => {
    if (props.isAuthenticated) {
      setShowLoginModal(false);
    }

    if (props.isAuthenticated && bookClicked) {
      setShowLoginModal(false);
      if (
        props?.party?.data?.host === "" ||
        (props.party &&
          props?.party?.data?.host &&
          props?.party?.data?.host) === (userObj && userObj.id)
      ) {
        history.push(`/booking/party/${props?.party?.data?.eventId}`);
      }
    }
  }, [props.isAuthenticated, bookClicked]);

  function handleClick(e) {
    e.preventDefault();
    history.push(`/booking/party/${props?.party?.data?.eventId}`);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function handlePayment() {
    for (const guest of props.guests) {
      if (!guest.guest_name || !guest.guest_mobile || !guest.guest_gender) {
        props.setError(true);
        showErrorToast("Please fill in all guest details before proceeding...");
        return;
      }
    }
    props.setError(false);
    setIsPaying(false);

    let finalGuestArray = [...props.guests];
    finalGuestArray.shift();
    let propsGuests = finalGuestArray?.map((guest) => {
      if (Object.keys(guest).length > 0) {
        guest.guest_gender === "Female"
          ? (guest.guest_gender = "female")
          : (guest.guest_gender = "male");
        // if (guest.hasOwnProperty('id')) {
        // 	delete guest.id;
        // }
      }
      return guest;
    });

    let mainGuestArray = propsGuests?.map((item) => {
      return {
        fullName: item.guest_name,
        phone: item.guest_mobile,
        gender: item.guest_gender,
      };
    });
    mainGuestArray.push({
      fullName: userObj.fullName,
      phone: userObj.phone,
      gender: userObj.gender,
      profileImage: userObj.profileImage,
    });

    let finalAmount;
    let finalDiscount = 0;

    if (props.couponDiscount > 0) finalDiscount = props.couponDiscount;
    else finalDiscount = props.couponDiscount;

    if (finalDiscount > 0)
      finalAmount = (props.amount + props.TaxAmount - finalDiscount).toFixed(2);
    else finalAmount = (props.amount + props.TaxAmount).toFixed(2);

    const guestList = props?.guests?.map((guest, index) => {
      return {
        fullName: guest?.guest_name,
        phone: guest?.guest_mobile,
        gender: guest?.guest_gender,
        email: index === 0 ? guest?.guest_email : undefined,
      };
    });

    console.log(
      props.userObj,
      parseFloat(finalAmount),
      props.party,
      guestList,
      { code: promoCode }
    );
    setLoading(true);
    showRazorpay(
      {},
      props.userObj,
      parseFloat(finalAmount),
      props.party,
      guestList,
      { code: quotationData?.isCouponValid ? promoCode : "" },
      () => setLoading(false)
    );
  }

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return dateObject.toLocaleDateString("en-GB", options);
  }

  const checkCurrentTime = (dateTime) => {
    const unixTime = moment(dateTime, "YYYY-MM-DD HH:mm").unix();
    const currentUnixTime = moment().unix();

    if (unixTime <= currentUnixTime) {
      return true;
    } else {
      return false;
    }
  };

  const increaseGuests = () => {
    dispatch(updateGuestCount(guests + 1));
  };

  const decreaseGuests = () => {
    if (guests > 1) {
      dispatch(updateGuestCount(guests - 1));
    }
  };

  const isMobileScreen = window.innerWidth <= 768; // Adjust the threshold as needed

  // Define a CSS class for hiding on mobile screens
  const mobileHideStyle = isMobileScreen ? { display: "none" } : {};

  const getBookView = () => {
    return (
      <>
        <div className="topEvPrice">
          {props?.party?.data?.amount ? (
            <>
              <h5>₹{props?.party?.data?.amount}</h5>
              <p> per person</p>
            </>
          ) : (
            <div>
              <h5>Free event</h5>
            </div>
          )}
        </div>

        <div className="guestCountOp">
          <p>No. Of Guests</p>
          <div className="countCont">
            <button onClick={decreaseGuests}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-minus"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l14 0" />
              </svg>
            </button>
            <span>{guests}</span>
            <button
              className={
                props?.party?.data?.membersLimit <=
                  props?.party?.data?.acceptedParticipantsCount + guests
                  ? "toolTipReach show"
                  : "tollTipReach"
              }
              onClick={increaseGuests}
              disabled={
                props?.party?.data?.membersLimit <=
                props?.party?.data?.acceptedParticipantsCount + guests
              }
            >
              <div className="memReachToolTip">
                <span>Almost Full! Try adding lesser guests.</span>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="icon icon-tabler icons-tabler-outline icon-tabler-plus"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 5l0 14" />
                <path d="M5 12l14 0" />
              </svg>
            </button>
          </div>
        </div>

        {!props.hideGstNall && (
          <div className="unitEconomicsCont">
            <div className="d-flex justify-content-between align-items-center">
              <div className="leftUnit">
                <p>{`Sub total (₹${props?.party?.data?.amount} X ${guests})`}</p>
              </div>

              <div className="rightUnit">
                <p>₹ {quotationData?.subTotal}</p>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="leftUnit">
                <p>Taxes and charges</p>
              </div>

              <div className="rightUnit">
                <p>₹ {quotationData?.taxAndProcessing}</p>
              </div>
            </div>

            {quotationData?.isCouponValid && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="leftUnit">
                  <p>Promo ({promoCode})</p>
                </div>
                <div className="rightUnit">
                  <p>-₹ {quotationData?.couponAmount}</p>
                </div>
              </div>
            )}

            <div className="promoCont">
              <div className="promoInputCont">
                <div className="relative">
                  <div className="icon">
                    {quotationData?.isCouponValid ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="green"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                        <path d="M9 12l2 2l4 -4" />
                      </svg>
                    ) : (
                      // <IconRosetteDiscountCheck size={24} color="green" />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 15l6 -6" />
                        <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />
                        <circle
                          cx="14.5"
                          cy="14.5"
                          r=".5"
                          fill="currentColor"
                        />
                        <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                      </svg>
                      // <IconRosetteDiscount size={24} />
                    )}
                    {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 15l6 -6" />
                    <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />
                    <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                  </svg> */}
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Promo code"
                    value={promoCode}
                    onChange={(e) => {
                      setApiError("");
                      dispatch(updatePromoCode(e.target.value));
                    }}
                    disabled={!!quotationData?.isCouponValid}
                  />
                </div>
                {quotationData?.isCouponValid && (
                  <p className="appliedP">promo code applied</p>
                )}

                {quotationData?.isCouponValid ? (
                  <button onClick={handleRemovePromo} className="removered">
                    Remove
                  </button>
                ) : (
                  <button onClick={handleApplyPromo}>Apply</button>
                )}
              </div>
            </div>
            {promoCode != "" && <p style={{ color: "red" }}>{apiError} </p>}
          </div>
        )}
        {props.hideGstNall && (
          <>
            {quotationData?.isCouponValid ? (
              <div className="promoCont">
                <div className="promoInputCont">
                  <div className="relative">
                    <div className="icon">
                      {quotationData?.isCouponValid ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="green"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount-check"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                          <path d="M9 12l2 2l4 -4" />
                        </svg>
                      ) : (
                        // <IconRosetteDiscountCheck size={24} color="green" />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 15l6 -6" />
                          <circle
                            cx="9.5"
                            cy="9.5"
                            r=".5"
                            fill="currentColor"
                          />
                          <circle
                            cx="14.5"
                            cy="14.5"
                            r=".5"
                            fill="currentColor"
                          />
                          <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                        </svg>
                        // <IconRosetteDiscount size={24} />
                      )}
                    </div>
                    <input
                      type="text"
                      placeholder="Enter Promo code"
                      value={promoCode}
                      onChange={(e) => {
                        setApiError("");
                        dispatch(updatePromoCode(e.target.value));
                      }}
                      disabled={!!quotationData?.isCouponValid}
                    />
                  </div>
                  {quotationData?.isCouponValid && (
                    <p className="appliedP">promo code applied</p>
                  )}

                  {quotationData?.isCouponValid ? (
                    <button onClick={handleRemovePromo} className="removered">
                      Remove
                    </button>
                  ) : (
                    <button onClick={handleApplyPromo}>Apply</button>
                  )}
                </div>
              </div>
            ) : (
              <div className="staticApply" onClick={props.bottomActivate}>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-rosette-discount"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 15l6 -6" />
                    <circle cx="9.5" cy="9.5" r=".5" fill="currentColor" />
                    <circle cx="14.5" cy="14.5" r=".5" fill="currentColor" />
                    <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1" />
                  </svg>
                  <p>Apply promo code</p>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-chevron-right"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9 6l6 6l-6 6" />
                </svg>
              </div>
            )}
          </>
        )}

        <div className="finaltotal">
          <div className="d-flex justify-content-between align-items-center">
            <div className="leftUnit">
              <p>Total</p>
            </div>

            <div className="rightUnit">
              <p>₹ {quotationData ? quotationData?.total : ""}</p>
            </div>
          </div>
        </div>

        {props.fakeContinue && (
          <button
            className="btn btn-reg btn-primary btn-padding d-inline-block w-full"
            onClick={props.closeNall}
          >
            Continue
          </button>
        )}

        {props.view != "receipt" && (
          <div onClick={scrollToTop}>
            <button
              onClick={(e) => {
                scrollToTop();
                handleClick(e);
              }}
              className={
                new Date(formatDate(partyDate)).getTime() >=
                new Date(formatDate(currentDateTime)).getTime() &&
                `btn btn-reg btn-primary fw-bold btn-padding d-inline-block w-full`
              }
              style={{ marginBottom: props.show ? "25px" : "0px" }}
              disabled={
                props?.party?.data?.membersLimit <
                props?.party?.data?.acceptedParticipantsCount + guests
              }
            >
              {"Request to Book"}
            </button>
          </div>
        )}

        {props.error && (
          <p
            className="show-error-message-pay text-primary mx-6"
            style={{ color: "red", padding: "10px" }}
          >
            Please fill in all guest details before proceeding...
          </p>
        )}
      </>
    );
  };

  const getSoldOutView = (isClosed) => {
    return (
      <>
        <a
          className={`btn btn-reg stockOut btn-padding d-inline-block w-full`}
          style={{ marginBottom: props.show ? "25px" : "0px" }}
        >
          {isClosed ? "Booking Closed" : "Sold Out"}
        </a>

        <h5 className="betterluckNextTime">Better luck next time!</h5>
      </>
    );
  };

  const getAlreadyInPartyView = () => {
    return (
      <div className="youHaveAlreadyBook gradient-border">
        <div>
          <h3>You have already booked this party.</h3>
          <p>
            Fun Awaits! Respect the Guidelines, Enjoy the Party, Make New
            Friends! 🤩🥳
          </p>
        </div>
      </div>
    );
  };
  //default return
  return (
    <>
      {loading && getLoader()}
      <div
        className={props.hideCard ? "disNope" : "sticky-ticket "}
        id="sticky-ticket"
      >
        <div className={!props.show ? "shadow rounded-lg" : ""}>
          {props?.party?.data?.bookingStatus === 3 ? (
            getAlreadyInPartyView()
          ) : (
            <div className="text-center px-4 pt-3 pb-4">
              {props?.party?.data?.bookingStatus === 1 ||
                props?.party?.data?.bookingStatus === 2
                ? getSoldOutView(props?.party?.data?.bookingStatus === 1)
                : getBookView()}
            </div>
          )}
        </div>
        <Default>
          <Modal
            size="lg"
            show={showLoginModal}
            onHide={handleClose}
            backdrop="static"
            dialogClassName="modal-dimensions borderandWidth"
            centered
          >
            <Auth handleClose={handleClose} />
          </Modal>
        </Default>
        <Mobile>
          <Modal
            size="lg"
            show={showLoginModal}
            onHide={handleClose}
            backdrop="static"
            centered
          >
            <Auth handleClose={handleClose} />
          </Modal>
        </Mobile>
      </div>

      {props.view != "receipt" && (
        <Mobile>
          <div className={props.hideSmCard ? "disNope" : "bottomSelling "}>
            <div className="innerSelling">
              {props?.party?.data?.bookingStatus === 3 ? (
                getAlreadyInPartyView()
              ) : (
                <div>
                  {props?.party?.data?.bookingStatus === 1 ||
                    props?.party?.data?.bookingStatus === 2 ? (
                    getSoldOutView(props?.party?.data?.bookingStatus === 1)
                  ) : (
                    <>
                      <div className="d-flex justify-content-between">
                        <p>₹{props?.party?.data?.amount} Per Person</p>

                        <div className="countCont">
                          <button onClick={decreaseGuests}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-minus"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M5 12l14 0" />
                            </svg>
                          </button>
                          <span>{guests}</span>
                          <button
                            className={
                              props?.party?.data?.membersLimit <=
                                props?.party?.data?.acceptedParticipantsCount +
                                guests
                                ? "toolTipReach show"
                                : "tollTipReach"
                            }
                            onClick={increaseGuests}
                            disabled={
                              props?.party?.data?.membersLimit <=
                              props?.party?.data?.acceptedParticipantsCount +
                              guests
                            }
                          >
                            <div className="memReachToolTip">
                              <span>Almost Full! Try adding lesser guests.</span>
                            </div>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-plus"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M12 5l0 14" />
                              <path d="M5 12l14 0" />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <button
                        className="mobile_booking"
                        onClick={(e) => {
                          if (props.view === "receipt") {
                            handlePayment();
                          } else {
                            handleClick(e);
                          }
                        }}
                      >
                        <div className="leftmobBook">
                          <p>{guests} Guests</p>
                          <span>|</span>
                          <p>₹{quotationData?.total}</p>
                        </div>

                        <div className="rightM">Request To Book</div>
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </Mobile>
      )}
    </>
  );
});

export default BookTicket;
