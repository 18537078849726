import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  fetchEventsByHostId,
  fetchUser,
  fetchuserDatabyPhone,
  getInterests,
} from "../../../../api/requests";
import { Mobile, Default } from "../../../helpers/Breakpoints";
import HostCard from "../../../pages/Profile/Dashboard/hostCard";
import GuestPartiesInfo from "../../../pages/Profile/Dashboard/GuestPartiesInfo";
import { updateGuestCount, updatePromoCode } from "../../../../actions/guestBookActions";
import { getLoader } from "../../../../utils/utils";



function GuestDetails() {
  const { hostId, eventId } = useParams();

  const location = useLocation();
  const participantId = location.state?.data;

  const isAuthenticated = useSelector((state) => state.auth);
  const isData = useSelector((state) => state.auth.data);
  let userObj = {};
  const dispatch = useDispatch();

  if (isAuthenticated.isAuthenticated) {
    userObj = JSON.parse(isData ? isData : []);
  }

  const history = useHistory();
  const [loading, setLoader] = useState(false);
  // const [events, setEvents] = useState([]);
  const guestdata = location.state.data;

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     history.push("/");
  //   }
  // }, [isAuthenticated, history]);

  // useEffect(() => {
  //   let userData = fetchuserDatabyPhone(userObj.token, guestdata.phone).then(
  //     (response) => {
  //       setUserData(response);
  //     }
  //   );

  //   let eventsHostDetails = fetchEventsByHostId(
  //     "fetchEventsByHostId",
  //     hostId,
  //     null
  //   ).then((response) => {
  //     setEvents(response?.data);
  //   });
  // }, []);

  const [events, setEvents] = useState([]);

  const [profileData, setProfileData] = useState({});
  const [interestsList, setInterests] = useState([]);
  const [hostReviewData, setHostReviewData] = useState([]);

  const [formData, setFormData] = useState({
    occupation: "Accountant",
    languages: "English, Marathi",
    location: "Mumbai",
    uselessSkill: "I can sing Lorem Ipsum",
    favoriteMovie: "Chennai Express",
    favoriteTVShow: "Brooklyn 99",
    funFact: "Chennai Express",
  });

  const [selectedInterests, setSelectedInterests] = useState([]);

  const callApis = async () => {
    const response = await getInterests();

    if (response.status === "success") {
      setInterests(response.data);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }

    callApis();
  }, [isAuthenticated, history]);

  useEffect(() => {

    setLoader(true);
    fetchUser("fetchUser", null, participantId).then((response) => {
      setFormData(response?.data?.about);
      setSelectedInterests(response?.data?.interest?.map((item) => item.id));
      setProfileData(response?.data);
    }).finally(() => {
      setLoader(false);
    });

    // fetchEventsByHostId(hostId).then((response) => {
    //   setEvents(response?.data);
    // });
  }, []);


  const getSuccessTickSVG = (className = "correctIcon") => {
    return (
      <div className={`${className} comIc`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-check"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M5 12l5 5l10 -10" />
        </svg>
      </div>
    )
  }


  const getFailedTickSVG = (className = "wrongIcon") => {
    return (
      <div className={`${className} comIc`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-exclamation-mark"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 19v.01" />
          <path d="M12 15v-10" />
        </svg>
      </div>
    )
  }

  const getVerificationView = (statusId = 3, statusMessage = "Pending", isLoading = false) => {

    switch (statusId) {
      case 0:
        return (
          <div class
            Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}

              <p>Government ID</p>



            </div>

          </div>
        )
      case 1:
        return (
          <div class
            Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}
              <p>Government ID</p>

              <div className="verifyDocs">
                {statusMessage}
              </div>

            </div>
          </div>
        )
      case 2:
        return (
          <div class
            Name="listCommon">
            <div className="ll">
              {getSuccessTickSVG()}
              <p>Government ID</p>
            </div>

          </div>
        )
      case 3:
        return (
          <div class
            Name="listCommon">
            <div className="ll">
              {getFailedTickSVG()}
              <p>Government ID</p>
              <div className="verifyDocs">
                {statusMessage}
              </div>

            </div>


          </div>

        )

      default:
        return (
          <button
            id="add-governmentId"
            onClick={() => {
              // setDisableButton(true);
              // startOnFidoVerification(userObj, changeVerificationStatus);
            }}
            className="make-pointer border-0 btn btn-primary fw-bold text-uppercase"
            style={{ fontSize: '0.75rem' }}
          >
            ADD
          </button>
        )

    }
  }

  if (loading) {
    return getLoader();
  }

  return (
    <>
      {/* {userData && (
          <Default>
          <div
          style={{ minHeight: '70vh', margin: '7rem auto', textAlign: 'center' }}
        >
          
            <Container className="mt-4">
              <Row>
                <Col lg={8} md={8}>
                  <div
                    className="mb-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginLeft: "200px" }}>
                      <h4>Guest Info</h4>
                    </div>
                    <div style={{ marginRight: "-200px" }}>
                      <button
                        onClick={(e) => {
                          history.push("/parties/" + eventId);
                        }}
                        className="btn btn-primary fw-bold btn-padding"
                      >
                        <FontAwesomeIcon icon={faArrowLeft} size="md" />
                        Back
                      </button>
                    </div>
                  </div>
                  <div
                    style={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <div
                      className="d-flex justify-content-center"
                      style={{
                        gap: "20px",
                        width: "100%",
                        marginLeft: "200px",
                      }}
                    >
                      <div
                        className="card"
                        style={{
                          padding: "30px",
                          height: "790px",
                          width: "90%",
                        }}
                      >
                        <HostCard
                          userObj={userObj}
                          isLoading={false}
                          data={userData}
                        />
                      </div>
                      <div
                        className="card"
                        style={{
                          padding: "65px",
                          height: "790px",
                          width: "70%",
                        }}
                      >
                        <GuestPartiesInfo userId={userData.userId} />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
        
          </div>
          </Default>
        )} */}

      <div className="container">
        <div className="profileGridCommon">
          <div className="profileCard">
            <div className="profieInner">
              <div className="userImgBg">
                <img
                  src={profileData?.profileImage}
                  alt=""
                />
              </div>
              <div className="userIntro">
                <p>Hi, I am</p>
                <h1>{profileData?.fullName}</h1>
              </div>

              <div className="userInfo">
                <div className="d-flex">
                  <div className="col">
                    <h3>{profileData?.partiesAttended}</h3>
                    <p>Parties attended</p>
                  </div>
                  {profileData?.partiesHosted > 0 && (
                    <div className="col">
                      <h3>{profileData?.partiesHosted}</h3>
                      <p>Parties hosted</p>
                    </div>
                  )}
                  {profileData?.averageRating > 0 && (
                    <div className="col">
                      <h3>{profileData?.averageRating}</h3>
                      <p>Avg. rating</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="verifiedSection">
                <h2>Verified Information</h2>

                <div className="listOfDocs">
                  <div className="listCommon">
                    <div className="ll">
                      {profileData?.isPhoneVerified ? getSuccessTickSVG() : getFailedTickSVG()}
                      <p>Phone number</p>
                    </div>
                  </div>


                  {getVerificationView(profileData?.statusId, profileData?.statusMessage)}

                  <div className="listCommon">
                    <div className="ll">
                      {profileData?.statusId === 2 ? getSuccessTickSVG() : getFailedTickSVG()}
                      <p>Profile Status</p>
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>

          {formData &&

            <div className="profileContents">
              <div>
                <div className="userQualitiesCont sameMarbtm">
                  <h2 className="profileTitsCom"> About {profileData?.fullName}</h2>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="userinputValues">
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-briefcase"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                            <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
                            <path d="M12 12l0 .01" />
                            <path d="M3 13a20 20 0 0 0 18 0" />
                          </svg>
                        </div>
                        <p>I am {formData?.occupation}</p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-message"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 9h8" />
                            <path d="M8 13h6" />
                            <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                          </svg>
                        </div>
                        <p>I speak in {formData?.languages}</p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                            <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                          </svg>
                        </div>
                        <p>I live in {formData?.location}</p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-mood-smile-beam"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z" />
                            <path d="M10 10c-.5 -1 -2.5 -1 -3 0" />
                            <path d="M17 10c-.5 -1 -2.5 -1 -3 0" />
                            <path d="M14.5 15a3.5 3.5 0 0 1 -5 0" />
                          </svg>
                        </div>
                        <p>A fun fact about me is {formData?.funFact}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="userinputValues">
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-wand"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M6 21l15 -15l-3 -3l-15 15l3 3" />
                            <path d="M15 6l3 3" />
                            <path d="M9 3a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                            <path d="M19 13a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
                          </svg>
                        </div>

                        <p>My most useless skill is {formData?.uselessSkill}</p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-movie"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                            <path d="M8 4l0 16" />
                            <path d="M16 4l0 16" />
                            <path d="M4 8l4 0" />
                            <path d="M4 16l4 0" />
                            <path d="M4 12l16 0" />
                            <path d="M16 8l4 0" />
                            <path d="M16 16l4 0" />
                          </svg>
                        </div>
                        <p>My favourite movie is {formData?.favoriteMovie} </p>
                      </div>
                      <div className="boxWrapper">
                        <div className="squareIconBoxes">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="icon icon-tabler icons-tabler-outline icon-tabler-device-tv-old"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                            <path d="M16 3l-4 4l-4 -4" />
                            <path d="M15 7v13" />
                            <path d="M18 15v.01" />
                            <path d="M18 12v.01" />
                          </svg>
                        </div>
                        <p>My favourite TV show is {formData?.favoriteTVShow}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="sameMarbtm">
                  <h2 className="profileTitsCom">Interests</h2>
                </div>
                <div className="selectedIntCont">
                  {selectedInterests?.map((id, index) => (
                    <span className="fakeBadge" key={index}>
                      {
                        interestsList?.find(
                          (interest) => interest?.interestId === id
                        )?.interestName
                      }
                    </span>
                  ))}
                </div>
              </div>
            </div>
          }
        </div>
      </div>


    </>
  );
}

export default GuestDetails;
