export default function RenderDate(event_date) {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let JSON_date = new Date(event_date);
  const dayOfWeek = days[JSON_date.getDay()];
  const date = JSON_date.getDate();
  const month = months[JSON_date.getMonth()];

  const fullDate = `${month} ${date} | ${dayOfWeek}`;
  return fullDate;

}