import React, { useEffect, useState } from "react";
import Img from "react-cool-img";

import styles from "./Party.module.scss";

import { Link, useLocation, useParams, useHistory } from "react-router-dom";

import moment from "moment";

import { useQuery } from "react-query";
import {
  cancelParticipantWithoutRefund,
  cancelParty,
  cancelpaymentRefundParticipant,
  cancelPaymentRefundParticipantHalf,
  fetchallGuestEventsbyPhone,
  fetchUser,
} from "../../../../api/requests";
import { Loader } from "../../../helpers";
import { v4 } from "uuid";
import { useSelector } from "react-redux";
import { Button, Container, Modal } from "react-bootstrap";

export default function AsGuest({ token, ...props }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const userObjJson = useSelector((state) => state.auth.data);

  const userObj = isAuthenticated ? JSON.parse(userObjJson) : [];
  const { hostId } = useParams();
  const location = useLocation();

  const authState = useSelector((state) => state.auth);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    let userDetails = fetchUser("users", userObj?.token, userObj?.userId).then(
      (response) => {
        setUserData(response?.data);
      }
    );
    let dataFetched = fetchallGuestEventsbyPhone(
      userObj?.token,
      userObj?.phone,
      "Guest"
    ).then((resp) => {
      resp.data = resp.data.filter((payment) => {
        return (
          payment.transaction.status === "captured" ||
          payment.transaction.status === "refund"
        );
      });

      resp.data.sort((a, b) => {
        const dateA = new Date(a.eventDetails.eventDate);
        const dateB = new Date(b.eventDetails.eventDate);
        return dateB - dateA;
      });
      setData(resp);
    });
  }, []);

  const fetchEventData = () => {
    fetchallGuestEventsbyPhone(userObj.token, userObj.phone, "Guest").then(
      (resp) => {
        setData(resp);
      }
    );
  };

  const containerStyles = {
    maxHeight: "400px",
    overflowX: "auto",
  };

  return (
    <div className={styles["as-a-guest"]}>
      <p className="fw-bold text-uppercase text-primary">As a guest</p>
      <div>
        {/* {data && (
					<div className={styles['loading-overlay']}>
						<Loader />
					</div>
				)} */}
        {data && data?.data?.length === 0 && <p>Not attending any parties.</p>}
        <div style={containerStyles}>
          {data &&
            data?.data?.map((party) => {
              return (
                <PartyCardWithImage
                  key={v4()}
                  {...party}
                  fetchEventData={fetchEventData}
                />
              );
            })}
        </div>
        <Link to="/parties?page=1" className="btn btn-primary fw-bold mt-5">
          EXPLORE PARTIES
        </Link>
      </div>
    </div>
  );
}

function PartyCardWithImage(props) {
  const [showPopup, setShowPopup] = useState(false);

  const handleImageClick = () => {
    if (
      props.eventDetails.status !== "cancel" &&
      props.eventDetails.status !== "hold"
    ) {
      history.push(`/parties/${props.eventId}`);
    } else {
      setShowPopup(!showPopup);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const partyDateTime = props.eventDetails.startTime;
  const partyDate = new Date(props.eventDetails.eventDate);

  const currentDateTime = moment().format("YYYY-MM-DD");

  const bookingStatus = props.participant.status;

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  let userObj = useSelector((state) => state.auth.data);

  userObj = isAuthenticated ? JSON.parse(userObj) : [];

  const [ticketModal, setTicketModal] = useState(false);
  const [response, setResponse] = useState("");

  const location = useLocation();
  const history = useHistory();

  const handleCancelClick = () => {
    setTicketModal(true);
  };

  function formatDateByme(inputDate) {
    const dateObject = new Date(inputDate);
    const options = { day: "numeric", month: "long", year: "numeric" };
    return dateObject.toLocaleDateString("en-GB", options);
  }

  const handleTicketModal = () => {
    setTicketModal(false);
  };

  const formatTime = (time) => {
    const date = new Date();
    const [hours, minutes] = time.split(":");
    date.setHours(hours);
    date.setMinutes(minutes);

    const options = { hour: "numeric", minute: "numeric", hour12: true };
    return date.toLocaleTimeString("en-US", options);
  };

  const isDifference24Hours = (givenDate, time) => {
    const givenDateObj = moment(givenDate + " " + time + ":00");
    const currentDateObj = moment();
    const hoursDifference = givenDateObj.diff(currentDateObj, "hours");
    return Math.abs(hoursDifference) < 24;
  };

  const isDifference48Hours = (givenDate, time) => {
    const givenDateObj = moment(givenDate + " " + time + ":00");
    const currentDateObj = moment();
    const hoursDifference = givenDateObj.diff(currentDateObj, "hours");
    return Math.abs(hoursDifference) < 48;
  };

  const handleContinue = () => {
    // setTicketModal(false)
    let cancelRequest = {
      eventId: props.eventId,
    };

    cancelParty(userObj.token, cancelRequest)
      .then((response) => {
        if (response.status == "success") {
          setResponse("Cancelled Successfully.");
          props.fetchEventData();
          setTimeout(() => {
            setResponse(null);
            setTicketModal(false);
          }, 2000);
        } else {
          setResponse("Something went wrong, try again in some time");
        }
      })
      .catch((error) => {
        setResponse("Something went wrong, try again in some time");
      });

    return;

    // let obj = {
    // 	"paymentId": props.transaction.id,
    // 	"participantId": props.participantId
    // }

    // //existing flow
    // if (isDifference48Hours(props.eventDetails.eventDate, props.eventDetails.startTime)) {
    // 	if (isDifference24Hours(props.eventDetails.eventDate, props.eventDetails.startTime) && props.participant.status !== 'Pending') {
    // 		const innerObj = { "participantId": props.participantId }
    // 		cancelParticipantWithoutRefund(userObj.token, innerObj).then((response) => {
    // 			if (response.status == 'success') {
    // 				setResponse("Cancelled Successfully.")
    // 				props.fetchEventData()
    // 				setTimeout(() => {
    // 					setResponse(null)
    // 					setTicketModal(false)
    // 				}, 2000);
    // 			} else {
    // 				setResponse("Something went wrong, try again in some time")
    // 			}

    // 		}).catch((error) => {
    // 			setResponse("Something went wrong, try again in some time")
    // 		})
    // 	} else if (isDifference24Hours(props.eventDetails.eventDate, props.eventDetails.startTime) && props.participant.status === 'Pending') {
    // 		cancelpaymentRefundParticipant(userObj.token, obj).then((response) => {
    // 			if (response.status == 'success') {
    // 				setResponse("Cancelled Successfully.")
    // 				props.fetchEventData()
    // 				setTimeout(() => {
    // 					setResponse(null)
    // 					setTicketModal(false)
    // 				}, 2000);
    // 			} else {
    // 				setResponse("Something went wrong, try again in some time")
    // 			}
    // 		}).catch((error) => {
    // 			setResponse("Something went wrong, try again in some time")
    // 		})
    // 	} else {
    // 		cancelPaymentRefundParticipantHalf(userObj.token, obj).then((response) => {
    // 			if (response.status == 'success') {
    // 				setResponse("Cancelled Successfully.")
    // 				props.fetchEventData()
    // 				setTimeout(() => {
    // 					setResponse(null)
    // 					setTicketModal(false)
    // 				}, 2000);
    // 			} else {
    // 				setResponse("Something went wrong, try again in some time")
    // 			}

    // 		}).catch((error) => {
    // 			setResponse("Something went wrong, try again in some time")
    // 		})
    // 	}
    // } else {
    // 	cancelpaymentRefundParticipant(userObj.token, obj).then((response) => {
    // 		if (response.status == 'success') {
    // 			setResponse("Cancelled Successfully.")
    // 			props.fetchEventData()
    // 			setTimeout(() => {
    // 				setResponse(null)
    // 				setTicketModal(false)
    // 			}, 2000);
    // 		} else {
    // 			setResponse("Something went wrong, try again in some time")
    // 		}
    // 	}).catch((error) => {
    // 		setResponse("Something went wrong, try again in some time")
    // 	})
    // }
  };

  const getCancellationNote = (event) => {
    if (
      isDifference48Hours(
        props.eventDetails.eventDate,
        props.eventDetails.startTime
      )
    ) {
      if (
        isDifference24Hours(
          props.eventDetails.eventDate,
          props.eventDetails.startTime
        ) &&
        props.participant.status !== "Pending"
      ) {
        return (
          <p className="text-primary">
            Note: If you cancel this house party now, no amount will be refunded
            as per the policies.
          </p>
        );
      } else if (
        isDifference24Hours(
          props.eventDetails.eventDate,
          props.eventDetails.startTime
        ) &&
        props.participant.status === "Pending"
      ) {
        return (
          <p style={{ color: "green" }}>
            Note: As per the cancellation policies, you are eligible to 100%
            refund.
          </p>
        );
      } else {
        return (
          <p className="text-primary">
            Note: As per the cancellation policies, you are eligible to 50%
            refund.
          </p>
        );
      }
    } else {
      return (
        <p style={{ color: "green" }}>
          Note: As per the cancellation policies, you are eligible to 100%
          refund.
        </p>
      );
    }
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className={`${styles["card-with-img"]} ${
        bookingStatus === "Pending"
          ? "Pending"
          : bookingStatus === "Rejected"
          ? null
          : "Accepted"
      } shadow`}
    >
      <div className={styles["card-with-img__img"]} onClick={handleImageClick}>
        <Img
          style={{
            borderTopLeftRadius: "15px",
            borderBottomLeftRadius: "15px",
            objectFit: "cover",
          }}
          src={props?.eventDetails.images[0]}
          alt="party attending"
        />
      </div>
      <Modal show={showPopup} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Event Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ marginLeft: "70px", fontSize: "18px" }}>
            This event is either on Hold or Canceled...
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        backdrop="static"
        show={ticketModal}
        onHide={handleTicketModal}
        centered
      >
        <Container className=" text-center py-5">
          <h5 className="fw-bold text-uppercase">
            Are You Sure You Want to Cancel?
          </h5>
          <p>
            Are you sure you want to cancel your booking for [Event Name]? This
            action cannot be undone.
          </p>
          {getCancellationNote()}
          <br></br>
          {response && <p>{response}</p>}
          <div className="commonPopUpBtn">
            <button
              onClick={handleContinue}
              className="btn btn-reg btn-primary btn-padding"
            >
              Confirm Cancellation
            </button>
            <button
              onClick={handleTicketModal}
              className="btn btn-reg btn-secondary  btn-padding mx-2"
            >
              Go Back
            </button>
          </div>
        </Container>
      </Modal>
      <div
        className={`${styles["card-with-img__content"]} padding-card  py-3 pr-3 ml-3`}
      >
        <div
          className="d-flex justify-content-between"
          onClick={handleImageClick}
        >
          <div>
            <Modal show={showPopup} onHide={handleClosePopup}>
              <Modal.Header closeButton>
                <Modal.Title>Event Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{ marginLeft: "70px", fontSize: "18px" }}>
                  This event is either on Hold or Canceled...
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePopup}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <h5 className="fw-bold">{props.eventDetails.title}</h5>
            <small className="m-0 text-muted">
              {moment(props.eventDetails.eventDate).format("MMM DD")} |{" "}
              {formatTime(props.eventDetails.startTime)}-
              {formatTime(props.eventDetails.endTime)}
            </small>
            <br />
            <small className="m-0 text-muted">
              {/* USE CITYNAME and LOCALITY */}
              {props.eventDetails.location && props.eventDetails.location}
              {/* {props.event_location && props.event_location.city} */}
            </small>
          </div>
          <div>
            <h5 className="fw-bold mr-1 price">
              {props.eventDetails.amount ? (
                <>&#8377; {props.eventDetails.amount}</>
              ) : (
                <>FREE</>
              )}
            </h5>
          </div>
        </div>
        <div className="d-flex justify-content-between status-css">
          <p
            className="fw-bold m-0"
            style={{
              color:
                props.participant.status === "Pending"
                  ? "orange"
                  : props.participant.status === "Rejected"
                  ? "red"
                  : props.participant.status === "Refund"
                  ? "navy"
                  : "green",
            }}
          >
            {props.participant.status === "Pending" ? (
              <>PENDING</>
            ) : props.participant.status === "Rejected" ? (
              <>DISAPPROVED</>
            ) : props.participant.status === "Refund" ? (
              <>REFUNDED</>
            ) : (
              <>APPROVED</>
            )}
          </p>
          {props.eventDetails.status == "live"
            ? (props.participant.status == "Accepted" ||
                props.participant.status == "Pending") &&
              moment(partyDate).unix() >= moment(currentDateTime).unix() && (
                <p
                  className="fw-bold m-0"
                  style={{ color: "red", cursor: "pointer" }}
                  onClick={handleCancelClick}
                >
                  Cancel Request
                </p>
              )
            : null}
        </div>
      </div>
    </div>
  );
}

function PartyCardWithoutImage(props) {
  return (
    <div className={`${styles["card-without-img"]} shadow`}>
      <div className={`${styles["card-without-img__content"]}`}>
        <div className="d-flex justify-content-between p-3">
          <div>
            <h5 className="fw-bold">{props.title}</h5>
            <small className="m-0 text-muted location">
              {moment(props.event_date).format("MMM DD")} | {props.startTime}-
              {props.endTime}
            </small>
            <br />
            <small className="m-0 text-muted location">Khar West, Mumbai</small>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <h5 className="fw-bold text-right price">
              {props.amount ? <>&#8377; props.event_price</> : <>FREE</>}
            </h5>
            <p className="fw-bold text-primary m-0">
              {props.participant.status.toLowerCase() === "Pending" ? (
                <>PENDING</>
              ) : props.participant.status.toLowerCase() === "Rejected" ? (
                <>REJECTED</>
              ) : (
                <>APPROVED</>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
